const config_settings = {
  "localhost:3000": {
    SERVER_URL: "https://int-report-api.myinspection.report/public/index.php/api",
    PORTAL_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
  },
  "localhost:3001": {
    SERVER_URL: " https://stg-report-api.myinspection.report/public/index.php/api",
    PORTAL_URL: "https://stg-portal-api.palmtech.com/public/index.php/api",
  },
  "develop.myinspection.report": {
    SERVER_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
    PORTAL_URL: "https://palmtechdevelop.yourinspection.report/public/index.php/api",
  },
  "integration.myinspection.report": {
    SERVER_URL: "https://int-report-api.myinspection.report/public/index.php/api",
    PORTAL_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
  },
  "staging.myinspection.report": {
    SERVER_URL: " https://stg-report-api.myinspection.report/public/index.php/api",
    PORTAL_URL: "https://stg-portal-api.palmtech.com/public/index.php/api",
  },
  "myinspection.report": {
    SERVER_URL: " https://prod-report-api.myinspection.report/public/index.php/api",
    PORTAL_URL: "https://prod-portal-api.palmtech.com/public/index.php/api",
  },
  "$PRID-irp.palmtechpr.com": {      /// Use this code of block for PR Tetsing environment ////
    SERVER_URL: "https://pr-121-irapi.palmtechpr.com/api",
    PORTAL_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
  },
};

export default config_settings;
