import toast from "react-hot-toast";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { apiCall } from "../../utils/action";
import { useLocation } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import FlagIcon from "@mui/icons-material/Flag";
import { useMediaQuery } from "@material-ui/core";
import { Grid, ClickAwayListener } from "@mui/material";
import { Typography, Collapse } from "@material-ui/core";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ImageCarousel from "../../common/Carousel/ImageCarousel";
import MediaGallery from "../../common/MediaGallery/MediaGallery";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RequestIconsCard from "../../components/Card/RequestIconsCard";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import RequestInfoInputCard from "../../components/Card/RequestInfoInputCard";

const CommentsSection = (props) => {
  let media = [];
  const [expanded, setExpanded] = useState(false);
  const [hideArrow, setHideArrow] = useState(false);
  const handleExpanded = () => {
    if (pathName !== "/repair-list") {
      if (!hideArrow) {
        setExpanded((prev) => !prev);
      }
    }
  };
  const [localRepairSelection, setLocalRepairSelection] = useState(false);
  const { prompt, getReportData, recommendation, comment_input_controls, comment_request_list, uuid, templateId, categoryId, Comprompt } = props;
  const [isRepairSelected, setIsRepairSelected] = useState(comment_request_list[0]?.is_selected === 1 ? true : false);
  const [comment, setComment] = useState(comment_request_list[0]?.request_list_data[0]?.comment_request_list_info ? comment_request_list[0]?.request_list_data[0]?.comment_request_list_info : "");
  const [cost, setCost] = useState(comment_request_list[0]?.request_list_data[0]?.comment_request_list_pricing ? comment_request_list[0]?.request_list_data[0]?.comment_request_list_pricing : "");
  const location = useLocation();
  const pathName = location.pathname;
  const [index, setIndex] = useState(0);
  let PhotosCount = 0;
  let VideosCount = 0;
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const matches = useMediaQuery("(max-width:900px)");
  let Medias = comment_input_controls?.filter((e) => e.type === "Media");
  const mediaFiles = Medias[0]?.data?.filter((e) => e.online === "online");
  mediaFiles?.forEach((value) => {
    if (value.type?.includes("image")) {
      media.push({ type: value.type, url: value.onlineUrl, media_id: `${value?.uuid}-${value?.created_at}`, desc: value.description, cat: `${Comprompt}` });
      PhotosCount = PhotosCount + 1;
    } else if (value.type?.includes("video")) {
      VideosCount = VideosCount + 1;
      media.push({ type: value.type, thumb: value.thumbnail, media_id: `${value?.uuid}-${value?.created_at}`, desc: value.description, url: value.onlineUrl, cat: `${Comprompt}` });
    }
  });
  function isDataLengthGreaterThanZero(inputControls) {
    let data = inputControls?.filter((e) => e?.type !== "Rating");
    for (let control of data) {
      if (control?.data?.length > 0) {
        return true; // Return true if any data array has length greater than 0
      }
    }
    return false; // Return false if none of the data arrays have length greater than 0
  }
  useEffect(() => {
    if (VideosCount > 0 || PhotosCount > 0 || comment_input_controls?.length > 0) {
      setExpanded(true);
    }
    if (comment_input_controls?.length === 0) {
      setExpanded(true);
      setHideArrow(true);
    } else if (comment_input_controls?.length > 0) {
      if (!isDataLengthGreaterThanZero(comment_input_controls)) {
        setExpanded(true);
        setHideArrow(true);
      }
    }
  }, [VideosCount, PhotosCount, comment_input_controls]);

  //  ? : media.push({ type: value.type, thumb: value.thumbnail, desc: value.description, url: value.onlineUrl, cat: `${prompt}` }))
  let ratingData = comment_input_controls?.find((comment) => comment.type === "Rating");
  let locationData = comment_input_controls?.find((comment) => comment.type === "Location");
  const handleRepairSelection = (isSelected, commentId) => {
    setLocalRepairSelection(false);
    if (isSelected === 1) {
      setIsRepairSelected(false);
      const formData = {
        comment_id: commentId,
        category_id: categoryId,
        template_id: templateId,
        line_id: props.lineUuid,
        request_data: [
          {
            comment_request_list_info: comment,
            comment_request_list_pricing: cost,
          },
        ],
        is_selected: 0,
        is_deleted: 0,
      };
      apiCall(
        (response) => {
          const { message, success } = response.data;
          if (success) {
            getReportData("noLoader");
            toast.success(message);
          } else {
            toast.error(message);
          }
        },
        formData,
        "STORE_REPAIR_DATA"
      );
    } else {
      setLocalRepairSelection(true);
      setIsRepairSelected((prev) => !prev);
      const formData = {
        comment_id: commentId,
        category_id: categoryId,
        template_id: templateId,
        line_id: props.lineUuid,
        request_data: [{
          comment_request_list_info: comment,
          comment_request_list_pricing: cost,
        },],
        is_selected: 1,
        is_deleted: 0,
      };
      apiCall(
        (response) => {
          const { message, success } = response.data;
          if (success) {
            getReportData("noLoader");
            toast.success(message);
          } else {
            toast.error(message);
          }
        },
        formData,
        "STORE_REPAIR_DATA"
      );
    }
  };

  const handleClickAway = (commentId) => {
    //   setIsRepairSelected((prev) => !prev);
    setLocalRepairSelection(false);
    const formData = {
      comment_id: commentId,
      category_id: categoryId,
      template_id: templateId,
      line_id: props.lineUuid,
      request_data: [
        {
          comment_request_list_info: comment,
          comment_request_list_pricing: cost,
        },
      ],
      is_deleted: 0,
      is_selected: 1,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          getReportData("noLoader");
          toast.success(message);
        } else {
          toast.error(message);
        }
      },
      formData,
      "STORE_REPAIR_DATA"
    );
  };
  function convertFontTagsToInlineStyles(htmlString) {
    // Replace <font> tags with inline styles
    var convertedString = htmlString?.replace(/<font([^>]*)>/gi, function (match, p1) {
      var attributes = p1?.split(/\s+/);
      var styles = "";
      const fontSizeConverter = (val) => {
        if (val === "1") {
          return 10;
        } else if (val === "2") {
          return 13;
        } else if (val === "3") {
          return 16;
        } else if (val === "4") {
          return 18;
        } else if (val === "5") {
          return 24;
        } else if (val === "6") {
          return 32;
        } else if (val === "7") {
          return 48;
        } else return val;
      };
      // Convert font attributes to inline styles
      for (var i = 0; i < attributes?.length; i++) {
        var attribute = attributes[i]?.trim();
        var parts = attribute?.split("=");

        if (parts.length === 2) {
          var name = parts[0]?.toLowerCase();
          var value = parts[1]?.replace(/['"]/g, "");
          // eslint-disable-next-line
          switch (name) {
            case "color":
              styles += "color: " + value + "; ";
              break;
            case "size":
              styles += "font-size: " + fontSizeConverter(value) + "px;";
              break;
          }
        }
      }

      return "<span style='" + styles + "'>";
    });
    convertedString = convertedString?.replace(/<\/font>/gi, "</span>");

    return convertedString;
  }
  const handleSetValue = () => {
    setComment(comment_request_list[0]?.request_list_data[0]?.comment_request_list_info || "");
    setCost(comment_request_list[0]?.request_list_data[0]?.comment_request_list_pricing || "");
    setLocalRepairSelection(true);
  };

  return (
    <div className={pathName === "/repair-list" && "minHeight200"}>
      <Grid container sx={{ mb: "17px", display: "flex" }} className="comment__content">
        <Grid item xs={pathName === "/repair-list" ? 10 : 12} md={pathName === "/repair-list" ? 9 : 9} lg={pathName === "/repair-list" ? 11 : 11} onClick={handleExpanded}>
          <div style={{ position: "relative" }} className={`comment__title ${matches ? "comment__mobile" : ""}`}>
            <div class="parent-container">
              <div class="overflow" style={{ whiteSpace: expanded ? "normal" : "nowrap", overflow: expanded ? "auto" : "hidden", textOverflow: "ellipsis", textAlign: "justify" }}>
                {(!props?.is_manual_prompt || props.is_manual_prompt === 0) && (prompt === "null" || prompt === null || prompt === "") ? (
                  recommendation ? (
                    expanded ? (
                      <p
                        class="overflow"
                        style={{ whiteSpace: expanded ? "normal" : "nowrap", overflow: expanded ? "auto" : "hidden", textOverflow: "ellipsis !important", textAlign: "left" }}
                        dangerouslySetInnerHTML={{ __html: convertFontTagsToInlineStyles(recommendation) }}></p>
                    ) : (
                      <p className="ellipsis-text">{recommendation?.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                    )
                  ) : (
                    ""
                  )
                ) : props?.is_mannual_prompt === 1 && prompt ? (
                  <p style={{ fontSize: "16px", color: "#3b3b3b", maxWidth: "70vw", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left" }}>{prompt}</p>
                ) : <p style={{ textAlign: "left" }}>{prompt}</p> ? (
                  <p style={{ fontSize: "16px", color: "#3b3b3b", maxWidth: "70vw", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left" }}>{prompt}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <span>{pathName !== "/repair-list" && matches ? expanded ? <ArrowDropUpIcon className="details-dropdownicon" /> : <ArrowDropDownIcon className="details-dropdownicon" /> : ""}</span>
          </div>
        </Grid>
        <Grid
          item
          xs={pathName === "/repair-list" ? 2 : 12}
          md={pathName === "/repair-list" ? 3 : 3}
          lg={pathName === "/repair-list" ? 1 : 1}
          onClick={pathName !== "/repair-list" ? handleExpanded : undefined}
          className="comment__rating__content">
          {pathName === "/repair-list" ? (
            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => localRepairSelection && handleClickAway(uuid)}>
              <Grid className="repair__selection__wrapper" id={`comment_${uuid}`}>
                <div
                  className={`repair__selection__icon ${comment_request_list[0]?.is_selected === 1 || isRepairSelected ? "active" : ""}`}
                  onClick={() => handleRepairSelection(comment_request_list[0]?.is_selected, uuid)}>
                  <DoneIcon />
                </div>
                <RequestIconsCard localRepairSelection={localRepairSelection} isRepairSelected={isRepairSelected} setLocalRepairSelection={setLocalRepairSelection} />
                <RequestInfoInputCard
                  isRepairSelected={isRepairSelected}
                  setIsRepairSelected={setIsRepairSelected}
                  localRepairSelection={localRepairSelection}
                  setLocalRepairSelection={setLocalRepairSelection}
                  comment={comment}
                  handleClickAway={() => handleClickAway(uuid)}
                  commentId={uuid}
                  setComment={setComment}
                  cost={cost}
                  setCost={setCost}
                />
              </Grid>
            </ClickAwayListener>
          ) : !matches && !hideArrow ? (
            expanded ? (
              <div class="arrow">
                <KeyboardArrowUpIcon className="details-dropdownicon" />
              </div>
            ) : (
              <div class="arrow">
                <KeyboardArrowDownIcon className="details-dropdownicon" />
              </div>
            )
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
          {/* <div className="comment_rating__inner"> */}
          {ratingData?.data.map((rating) => (
            <Typography className="comment__rating" key={rating.Id}>
              <span className="coment_rating_name" style={{ border: `1px solid ${rating.Color}` }}>
                <span style={{ background: `${rating.Color}` }} className="comment_rating_color">
                  {rating.include_in_summary === "1" || rating?.include_in_summary === 1 ? <FlagIcon /> : ""}
                </span>
                {rating.Name}
              </span>
            </Typography>
          ))}
          {VideosCount > 0 || PhotosCount > 0 ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ width: "5px", borderLeft: "1px solid #CCCCCC", height: "23px" }}></div>
            </div>
          ) : (
            ""
          )}
          {/* </div> */}
          {PhotosCount !== 0 ? (
            <Button variant="text" startIcon={<InsertPhotoRoundedIcon />} disableRipple className="count_button">
              {PhotosCount} {PhotosCount > 1 ? "photos" : "photo"}
            </Button>
          ) : (
            ""
          )}
          {VideosCount !== 0 ? (
            <Button variant="none" startIcon={<VideocamRoundedIcon />} disableRipple className="count_button">
              {VideosCount} {VideosCount > 1 ? "videos" : "video"}
            </Button>
          ) : (
            ""
          )}
        </Grid>

        {/* <Grid item xs={pathName === "/repair-list" ? 12 : 12} md={pathName === "/repair-list" ? 5 : 3} onClick={pathName !== "/repair-list" ? handleExpanded : undefined} className="comment__rating__content">
          <div className={`comment_rating__outer ${matches ? "is_mobile" : ""}`}>
            <div className="comment_rating__inner">
              {ratingData?.data.map((rating) => (
                <Typography className="comment__rating" key={rating.Id}>
                  <span className="coment_rating_name" style={{ border: `1px solid ${rating.Color}` }}>
                    <span style={{ background: `${rating.Color}` }} className="comment_rating_color">
                      {rating.include_in_summary === "1" || rating?.include_in_summary === 1 ? <FlagIcon /> : ""}
                    </span>
                    {rating.Name}
                  </span>
                </Typography>
              ))}
            </div>
            {pathName === "/repair-list" ? (
              <ClickAwayListener onClickAway={localRepairSelection ? () => handleClickAway(uuid) : undefined}>
                <Grid className="repair__selection__wrapper" id={`comment_${uuid}`}>
                  <div className={`repair__selection__icon ${comment_request_list[0]?.is_selected === 1 || isRepairSelected ? "active" : ""}`} onClick={() => handleRepairSelection(comment_request_list[0]?.is_selected, uuid)}>
                    <DoneIcon />
                  </div>
                  <RequestIconsCard localRepairSelection={localRepairSelection} isRepairSelected={isRepairSelected} setLocalRepairSelection={setLocalRepairSelection} />

                  <RequestInfoInputCard isRepairSelected={isRepairSelected} setIsRepairSelected={setIsRepairSelected} localRepairSelection={localRepairSelection} setLocalRepairSelection={setLocalRepairSelection} comment={comment} handleClickAway={() => handleClickAway(uuid)} commentId={uuid} setComment={setComment} cost={cost} setCost={setCost} />
                </Grid>
              </ClickAwayListener>
            ) : !matches ? (
              expanded ? (
                <ArrowDropUpIcon className="details-dropdownicon" />
              ) : (
                <ArrowDropDownIcon className="details-dropdownicon" />
              )
            ) : (
              ""
            )}
          </div>
        </Grid> */}

        <Grid item xs={12}>
          <Collapse in={pathName === "/repair-list" ? true : expanded}>
            {(!props?.is_manual_prompt || props.is_manual_prompt === 0) && (prompt === "null" || prompt === null || prompt === "") ? (
              ""
            ) : (
              <div>{recommendation ? <div className="recommendation_container" dangerouslySetInnerHTML={{ __html: convertFontTagsToInlineStyles(`${recommendation}`) }}></div> : ""}</div>
            )}
            {locationData !== null && locationData !== undefined && locationData?.data?.length !== 0 && (
              <Typography className="line-desc">
                <span className="capitalise">{locationData?.prompt} - </span>
                {typeof locationData.data === "string" ? (
                  <span className="line-value">{locationData.data}</span>
                ) : typeof locationData.data === "object" ? (
                  locationData?.data?.map((item, index) => {
                    return (
                      <>
                        <span className="line-value">
                          {item}
                          {index < locationData?.data?.length - 1 && ", "}
                        </span>
                      </>
                    );
                  })
                ) : (
                  ""
                )}
              </Typography>
            )}
            {/* Media Section */}
            <MediaGallery isCommentMedia prompt={Medias[0]?.prompt} mediaFiles={mediaFiles} setIndex={setIndex} setOpen={setOpen} />
          </Collapse>
        </Grid>
      </Grid>
      <ImageCarousel images={media} index={index} open={open} handleClose={handleClose} />
    </div>
  );
};

export default CommentsSection;
