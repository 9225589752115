import parse from "html-react-parser";
import Definitions from "./Definitions";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ReadMore } from "../../helpers/HelperFunctions";
import "../../../assets/styles/containers/_reportContent.scss";
import LineCategory from "../../components/lineCategory/LineCategory";
import { checkCategory, checkFilters } from "../../helpers/HelperFunctions";

const ReportContent = ({ categoriesData, selectedRatings = [], setReRender, getReportData, isNarrative }) => {
  const location = useLocation();
  const pathName = location.pathname;
  let count = 1;
  const includeInReport = categoriesData?.length && categoriesData[0]?.categories?.some((category) => category.includeInReport === true);

  const createCategoryContent = (category, prompt, catId, catIndex, introText, tempIndex, templateId, categoryId) => {
    // hiding the deleted category from reportwriter
    // if (category.is_deleted === 1) return false;
    return (
      <div id={`${prompt}_${catId}`} key={catIndex}>
        <Typography className="categories__title">
          {count}. {prompt !== null ? prompt : ""}
        </Typography>
        <Typography className="category__description"></Typography>
        <ReadMore body={introText}></ReadMore>
        <LineCategory
          lineContent={category?.lines}
          categoryIndex={count}
          templateIndex={tempIndex + 1}
          categoriesData={categoriesData}
          selectedRatings={selectedRatings}
          setReRender={setReRender}
          getReportData={getReportData}
          templateId={templateId}
          categoryId={categoryId}
        />
        <span style={{ display: "none" }}>{(count = count + 1)}</span>
        <br />
      </div>
    );
  };
  return (
    categoriesData !== undefined &&
    categoriesData
      ?.filter((item) => {
        // Check if the key exists in the object
        if (item.hasOwnProperty("is_pdf_template")) {
          // Filter based on a condition (if key exists)
          return item["is_pdf_template"] === 0; // Replace with your condition
        } else {
          // If the key doesn't exist, don't filter (include the item)
          return true;
        }
      })
      ?.map((template, templateIndex) => (
        <div key={templateIndex}>
          <Typography className="narrative_template_title" sx={{ mt: "82px !important", mb: "41px !important" }}>
            {template.report_title ? template.report_title : template.name}
          </Typography>
          {pathName === "/" && (
            <>
              <Definitions data={template?.rating_data} id={`rating_data_${template?.template_id}`} />
              <br />
              <br />
            </>
          )}
          {pathName === "/summary" && template?.summary_disclaimer !== null && <div className="summary_disclaimer">{parse(template?.summary_disclaimer)}</div>}
          {includeInReport || template?.is_texas === 0
            ? template?.categories
                ?.filter((e) => e.is_inspection_details !== 1 && e.is_inspection_details !== 2)
                ?.map((category, categoryIndex) => {
                  // hiding the deleted category from reportwriter
                  if (category.is_deleted === 1 || (category.includeInReport === false && template?.is_texas === 1)) return "";
                  else {
                    if (pathName === "/summary") {
                      if (checkCategory(category))
                        return createCategoryContent(
                          category,
                          category?.prompt,
                          category?.category_id,
                          categoryIndex,
                          category?.introductory_text,
                          templateIndex,
                          template?.template_id,
                          category?.category_id
                        );
                      else {
                        count = count + 1;
                        return false;
                      }
                    } else if (pathName === "/repair-list") {
                      if (checkFilters(category, selectedRatings))
                        return createCategoryContent(
                          category,
                          category?.prompt,
                          category?.category_id,
                          categoryIndex,
                          category?.introductory_text,
                          templateIndex,
                          template?.template_id,
                          category?.category_id
                        );
                      else {
                        count = count + 1;
                        return false;
                      }
                    }
                    return createCategoryContent(
                      category,
                      category?.prompt,
                      category?.category_id,
                      categoryIndex,
                      category?.introductory_text,
                      templateIndex,
                      template?.template_id,
                      category?.category_id
                    );
                  }
                })
            : null}
          <span style={{ display: "none" }}>{(count = 1)}</span>
        </div>
      ))
  );
};

export default ReportContent;
