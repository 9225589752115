import { Link as Scroll } from "react-scroll";
import { useLocation } from "react-router-dom";
import { PoweredBy } from "../../../assets/svg/Svg";
import ISNLogo from "../../../assets/images/ISN.png";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import "../../../assets/styles/containers/_sidemenu.scss";
import PBPTLogo from "../../../assets/images/PoweredPT.png";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { checkCategory, checkFilters } from "../../helpers/HelperFunctions";
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";

const drawerWidth = 220;
const SideMenu = ({ dataLoaded, categoriesData, selectedRatings, isISN, showLoader, isNarrative }) => {
  const location = useLocation();
  let count = 1;
  const pathName = location.pathname;
  const includeInReport = categoriesData?.length && categoriesData[0]?.categories?.some((category) => category.includeInReport === true);

  return (
    <Drawer
      variant="permanent"
      className="sidemenu_drawer"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
        display: { xs: "none", md: "block" },
      }}>
      {pathName !== "/repair-list" && (
        <>
          <Toolbar />
          <Toolbar />
        </>
      )}
      <Box
        sx={{ overflow: "auto", paddingTop: 6, height: "100%" }}
        className={pathName !== "/repair-list" ? (isNarrative ? "narrative__sidemenu__wrapper" : "sidemenu__wrapper") : "sidemenu__wrapper full__height"}>
        {pathName === "/" && (
          <List>
            {["Inspection Details"]?.map((text, index) => (
              <Scroll className="scroller__menu" key={index} to={`inspection_details`} spy={true} smooth={true} offset={-170}>
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>{index === 0 ? <ContentPasteIcon /> : <MenuBookIcon />}</ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          fontFamily: "Open Sans",
                        },
                      }}
                      primary={text}
                    />
                  </ListItemButton>
                </ListItem>
              </Scroll>
            ))}
          </List>
        )}
        {pathName !== "/summary" || !isNarrative || includeInReport || categoriesData?.is_texas === 0
          ? categoriesData
              ?.filter((item) => {
                // Check if the key exists in the object
                if (item.hasOwnProperty("is_pdf_template")) {
                  // Filter based on a condition (if key exists)
                  return item["is_pdf_template"] === 0; // Replace with your condition
                } else {
                  // If the key doesn't exist, don't filter (include the item)
                  return true;
                }
              })
              ?.filter((e) => e?.is_deleted !== 1)
              ?.map((template, index) => (
                <>
                  <div key={index}>
                    {template?.categories
                      ?.filter(
                        (category) =>
                          // Condition 1: Check if category has introductory text
                          category?.introductory_text ||
                          // Condition 2: Check if category lines meet the criteria
                          category?.lines
                            ?.filter((line) => line?.is_deleted !== 1)
                            ?.some((line) => line?.comments?.filter((comment) => comment?.is_deleted !== 1)?.length > 0 || line?.introductory_text)
                      )
                      ?.filter(
                        (category) =>
                          // Exclude categories that are either inspection details or deleted
                          category?.is_inspection_details !== 1 && category?.is_deleted !== 1
                      )
                      ?.filter((e) => e?.is_deleted !== 1).length > 0 ? (
                      <>
                        {" "}
                        <Typography style={{ marginTop: "10px" }}>{template.report_title ? template.report_title : template.name}</Typography>{" "}
                        <Divider className={isNarrative ? "narratives_sidemenu_divider" : "sidemenu_divider"} />
                      </>
                    ) : (
                      ""
                    )}
                    {pathName === "/" && !isNarrative ? (
                      <List key={index}>
                        <Scroll className="scroller__menu" key={index} to={`rating_data_${template?.template_id}`} spy={true} smooth={true} offset={-150}>
                          <ListItem disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                <MenuBookIcon />
                              </ListItemIcon>
                              <ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    fontFamily: "Open Sans",
                                  },
                                }}
                                primary={"Definitions"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </Scroll>
                      </List>
                    ) : (
                      ""
                    )}
                    {template?.categories
                      ?.filter((e) => e?.is_deleted !== 1)
                      ?.map((category, index) => {
                        return (
                          <>
                            {category.is_inspection_details === 1 ||
                            category.is_inspection_details === 2 ||
                            category.is_deleted === 1 ||
                            (category.includeInReport === false && template?.is_texas === 1) ||
                            (category?.lines?.filter((e) => e?.is_deleted !== 1)?.length === 0 && !category?.introductory_text) ||
                            (isNarrative && category?.lines?.some((line) => line?.comments?.filter((e) => e?.is_deleted !== 1)?.length === 0) && !category?.introductory_text) ? (
                              ""
                            ) : (
                              <>
                                <List key={index}>
                                  <Scroll
                                    className={
                                      pathName === "/summary"
                                        ? checkCategory(category)
                                          ? "scroller__menu"
                                          : "scroller__menu disabled"
                                        : pathName === "/repair-list"
                                        ? checkFilters(category, selectedRatings)
                                          ? "scroller__menu"
                                          : "scroller__menu disabled"
                                        : "scroller__menu"
                                    }
                                    key={index}
                                    to={`${category?.prompt}_${category?.category_id}`}
                                    spy={true}
                                    smooth={true}
                                    offset={-150}>
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <BookmarkBorderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          primaryTypographyProps={{
                                            style: {
                                              width: "150px",
                                              wordWrap: "break-word", // This ensures words wrap properly
                                              whiteSpace: "normal", // This prevents the text from staying in a single line
                                              fontFamily: "Open Sans",
                                            },
                                          }}
                                          primary={`${count}. ${category?.prompt !== null ? category?.prompt : ""}`}
                                        />
                                      </ListItemButton>
                                      <div style={{ display: "none" }}>{(count = count + 1)}</div>
                                    </ListItem>
                                  </Scroll>
                                </List>
                              </>
                            )}
                          </>
                        );
                      })}
                    <div style={{ display: "none" }}>{(count = 1)}</div>
                  </div>
                </>
              ))
          : ""}
        {pathName !== "/summary" &&
        categoriesData?.filter((item) => {
          // Check if the key exists in the object
          if (item.hasOwnProperty("is_pdf_template")) {
            return item["is_pdf_template"] === 1; // Replace with your condition
          } else {
            return true;
          }
        })?.length > 0 ? (
          <>
            <Typography style={{ marginTop: "10px" }}>OTHER INSPECTION FORMS</Typography>
            <Divider style={{ marginBottom: "3px" }} />
          </>
        ) : (
          ""
        )}
        {pathName !== "/summary" &&
          categoriesData
            ?.filter((item) => {
              // Check if the key exists in the object
              if (item.hasOwnProperty("is_pdf_template")) {
                // Filter based on a condition (if key exists)
                return item["is_pdf_template"] === 1; // Replace with your condition
              } else {
                // If the key doesn't exist, don't filter (include the item)
                return true;
              }
            })
            .map((template, index) => (
              <>
                <div key={index}>
                  {/* <Divider style={{ marginBottom: "3px" }} /> */}
                  {/* {pathName === "/" && ( */}
                  <List key={index}>
                    <Scroll
                      className={pathName === "/summary" || pathName === "/repair-list" ? "scroller__menu disabled" : "scroller__menu"}
                      key={index}
                      to={`${template?.name}`}
                      spy={true}
                      smooth={true}
                      offset={-300}>
                      <ListItem disablePadding sx={{ pr: 1 }}>
                        <ListItemButton>
                          <ListItemIcon>
                            <ContentPasteIcon />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontFamily: "Open Sans",
                              },
                            }}
                            primary={`${template?.name}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Scroll>
                  </List>
                  {/* )} */}

                  <div style={{ display: "none" }}>{(count = 1)}</div>
                </div>
              </>
            ))}
      </Box>
      {!showLoader && !isNarrative && dataLoaded ? (
        isISN ? (
          <div style={{ backgroundColor: "#f5f6f8", height: "10vh" }}>
            <div style={{ position: "absolute", bottom: 0 }}>
              <div style={{ backgroundColor: "#f5f6f8", height: "100%" }}>
                <div className="powered_by_ISN">
                  <PoweredBy />
                </div>
                <div>
                  <img className="isn_png" onClick={() => window.open("http://inspectionsupport.com/", "_blank")} src={ISNLogo} alt="ISN_logo" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ backgroundColor: "#f5f6f8", height: "10vh" }}>
            <div style={{ backgroundColor: "#f5f6f8", height: "100%", position: "relative" }}>
              <img style={{ position: "absolute", bottom: 0 }} onClick={() => window.open("http://palmtech.com/", "_blank")} src={PBPTLogo} alt="PT_logo" className="powered_by_PT" />
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </Drawer>
  );
};

export default SideMenu;
